<template>
  <div class="agreement wrapper">
    <p class="title-top" />
    <!--    eslint-disable vue/no-v-html-->
    <div
      class="div-partition"
      v-html="$t('agreement.IOSPrivacy', { project })"
    />
  </div>
</template>

<script>
import { formatFirstUpperCase } from '@/utils/index.js'

export default {
  name: 'PrivacyPolicy',
  metaInfo: {
    title: 'PrivacyPolicy',
    link: []
  },
  data () {
    return {
      project: 'Netboom Community'
    }
  },
  created () {
    const flavor = this.$route.query.flavor || 'Netboom Community'
    this.project = formatFirstUpperCase(flavor)
  }
}
</script>

<style lang="less">
  @import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
  .wrapper{
    padding: 0 5%;
    background:#F0F0F0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    min-height: 100%;
    box-sizing: border-box;
  }
</style>
